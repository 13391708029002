import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
// import info from '../assets/icon-info.svg';
import { Button, Checkbox, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Hedaer from './Hedaer';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

// Yup validation schema
const validationSchema = Yup.object({
    totalGuests: Yup.number().required('Please enter the approximate number of guests').positive('Must be a positive number').integer('Must be an integer'),
});

function Dashboard() {
    const navigate = useNavigate();

    const [types, setTypes] = useState();
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [products, setProducts] = useState({});
    const [additionalItems, setAdditionalItems] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedCardId, setSelectedCardId] = useState(null);

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            date: '',
            venuePost: '',
            totalGuests: '',
            seats: '',
            // interestedInTipis: false,
            // interestedInSailcloth: false,
            // interestedInStretchTents: false,
        },
        resolver: yupResolver(validationSchema),
    });

    const fetchTypes = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/types`);
            setTypes(response.data)
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    useEffect(() => {
        fetchTypes();
    }, []);

    const fetchProducts = async (typeId) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/types/${typeId}/products`);
            setProducts(prevProducts => {
                const newProducts = {
                    ...prevProducts,
                    [typeId]: response.data,
                };
                updateAdditionalItems(newProducts);
                setSelectedCardId(response?.data?.products[0]?.id);
                setSelectedProduct(response.data.products)
                return newProducts;
            });
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };
    const updateAdditionalItems = (products) => {
        const inventories = Object.values(products || {}).flatMap(category => category.products || []);
        const itemMap = new Map();

        inventories.forEach(product => {
            const productInventories = product.inventories || [];
            productInventories.forEach(inventory => {
                itemMap.set(inventory.name, {
                    id: inventory.id,
                    name: inventory.name,
                    checked: inventory.default,
                    shape: inventory.shape,
                    price: inventory.price,
                    type: inventory.type,
                    info_text: inventory.info_text,
                    qty: inventory.qty,
                });
            });
        });

        const uniqueItems = Array.from(itemMap.values());
        setAdditionalItems(uniqueItems);
    };

    // Handle checkbox changes for selecting types
    const handleCheckboxChange = (id) => {
        setSelectedTypes(prevSelected => {
            if (prevSelected.includes(id)) {
                const updatedSelectedTypes = prevSelected.filter(typeId => typeId !== id);
                setProducts(prevProducts => {
                    const updatedProducts = { ...prevProducts };
                    delete updatedProducts[id];
                    updateAdditionalItems(updatedProducts);
                    return updatedProducts;
                });
                return updatedSelectedTypes;
            } else {
                fetchProducts(id);
                return [...prevSelected, id];
            }
        });
    };

    const onSubmit = (data) => {
        navigate("/tent", {
            state: {
                formData: data,
                selectedTypes: selectedTypes,
                types: types,
                products: products,
                additionalItems: additionalItems,
                selectedCardId: selectedCardId,
                selectedProduct: selectedProduct
            }
        });
    };

    const today = new Date().toISOString().split('T')[0];

    return (
        <div className='container'>
            <div className='header'>
                <Hedaer />
            </div>
            <div className='body'>
                <div className='pt-40 text-center'>
                    <h2 className='fs-35'>Event Details</h2>
                    <p className='mainBodyText'>
                        Please provide some basic event details so we can work out options. <br />
                        Completing as much of this information as possible allows for a more accurate quote.
                    </p>
                </div>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="date"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="date"
                                label="Event Date (if known)"
                                className='custom-textfield'
                                type="date"
                                {...field}
                                variant="filled"
                                inputProps={{
                                    min: today
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    sx: {
                                        color: 'white',
                                        position: 'absolute',
                                        top: '-1px',
                                        left: 0,
                                        fontSize: '16px',
                                    },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                        '& input': {
                                            color: 'white',
                                            padding: '10px 12px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderRadius: '0',
                                            borderBottom: '1px solid white',
                                        },
                                    },
                                    // backgroundColor: '#CC3456',
                                    borderRadius: '5px',
                                    width: '250px',
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="venuePost"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="venuePost"
                                label="Venue Postcode (if known)"
                                variant="filled"
                                {...field}
                                error={!!errors.venuePost}
                                helperText={errors.venuePost?.message}
                                className="custom-textfield"
                                sx={{
                                    '& .MuiFormHelperText-root': {
                                        color: 'black',
                                    },
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="totalGuests"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="totalGuests"
                                label="Total Guests Attending"
                                variant="filled"
                                type="text"
                                {...field}
                                error={!!errors.totalGuests}
                                helperText={errors.totalGuests?.message}
                                className="custom-textfield"
                                sx={{
                                    '& .MuiFormHelperText-root': {
                                        color: 'white',
                                    },
                                    '& .MuiFormHelperText-root.Mui-error': {
                                        color: 'white',
                                    },
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="seats"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="seats"
                                label="No. of Formal Dining Seats Required"
                                variant="filled"
                                type="text"
                                {...field}
                                error={!!errors.seats}
                                helperText={errors.seats?.message}
                                className="custom-textfield"
                                sx={{
                                    '& .MuiFormHelperText-root': {
                                        color: 'black',
                                    },
                                }}
                            />
                        )}
                    />

                    <Typography className='fs-16'>(enter zero if casual party)</Typography>

                    <div className='fs-16'>
                        {types?.length > 0 ? (
                            types.map(type => (
                                <div className='flexContainer' key={type.id}>
                                    <div className="cursor-context">Intersted In {type.name}</div>
                                    {/* <Controller
                                        name="interestedInTipis"
                                        control={control}
                                        render={({ field }) => ( */}
                                    <Checkbox
                                        // {...field}
                                        className="custom-checkbox"
                                        checked={selectedTypes.includes(type.id)}
                                        onChange={() => handleCheckboxChange(type.id)}
                                    />
                                    {/* )}
                                    /> */}
                                </div>
                            ))
                        ) : (
                            <div>Loading...</div>
                        )}
                    </div>

                    <div className='w-300 pt-4'>
                        <Button type="submit" variant='contained' className='main-button' endIcon={<ChevronRightIcon />}>
                            Tents & Options
                        </Button>
                    </div>
                </form>
            </div>
            <Box className="footer">
                <Typography className='fs-60 fw-7'>Let's get started</Typography>
                <Box className="contactInfo">
                    <Footer />
                </Box>
            </Box>
        </div>
    );
}

export default Dashboard;
