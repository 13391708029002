import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    TextField,
    MenuItem,
    Checkbox,
    Button,
    Grid,
    Box,
    useMediaQuery,
    useTheme,
    Typography
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../assets/logo.png';
import Footer from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import deleteTent from '../assets/delete-tent.svg';

const BASE_URL = process.env.REACT_APP_API_URL;

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    confirm_email: Yup.string()
        .oneOf([Yup.ref('email'), null], 'Emails must match')
        .required('Confirm Email is required'),
    phone: Yup.string().required('Phone number is required'),
});

function QuoteDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { shortlistedItems, products, dataFields } = location.state || {};
    const [items, setItems] = useState(shortlistedItems || []);

    const handleDelete = (id) => {
        setItems(prevItems => prevItems.filter(item => item.id !== id));
    };

    const homePage = () => {
        navigate("/")
    }
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: '',
            email: '',
            confirm_email: '',
            event_type: '',
            phone: '',
            notes: '',
            send_brochure: false,
        }
    });

    const submitData = async (data) => {
        try {
            const transformedData = {
                products: items.map(item => ({
                    product_id: item.id,
                    selected_inventories: item.inventories
                        // .filter(inv => inv.default)
                        .map(inv => {
                            return { [inv.id]: inv.qty || 0, "shape": inv.shape };
                        })
                })),
                contact_information: {
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    event_type: data.event_type,
                    notes: data.notes,
                    brochure: data.send_brochure,
                    total_seats: dataFields.seats,
                    date: dataFields.date,
                    total_guest: dataFields.totalGuests,
                    venue_post: dataFields.venuePost
                }
            };

            const response = await axios.post(`${BASE_URL}/api/order`, transformedData);
            console.log('Server Response:', response.data);
            navigate('/thankyou');

            reset();
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };


    return (
        <div className='quoteContainer'>
            <div className="headerContainer">
                <div className="leftSideBar">
                    <img src={logo} alt="Logo" width="50%" className='cursor-pointer' onClick={homePage} />
                </div>
                <div className="rightSideBar">
                    <div className="stepper-container">
                        <ol className="stepper">
                            <li className="step">1</li>
                            <li className="step">2</li>
                            <li className="step active">3</li>
                            <li className="step">4</li>
                        </ol>
                    </div>
                </div>
            </div>

            <Grid container>
                <Grid item lg={8} md={8} sm={12}>
                    <div className='headerNav leftSideBarBottom'>
                        <div className='body quoteDetails'>
                            <div className='flexCenterColumn'>
                                <h2 className='fs-35'>Let's Start Talking</h2>
                                <p className='mainBodyText w-600 text-center'>
                                    We need to check if your selected tents are available on your event date, please complete the
                                    form below and a member of the team will come back to you with our availability.
                                    <br />
                                    <br />
                                    <small>*Please note all quotes are not valid until confirmed by a member of the team.</small>
                                </p>
                            </div>
                            <form onSubmit={handleSubmit(submitData)}>
                                <div className='form'>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Name"
                                                variant="filled"
                                                className="custom-textfield"
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        color: 'white',
                                                    },
                                                    '& .MuiFormHelperText-root.Mui-error': {
                                                        color: 'white',
                                                    },
                                                }}
                                                error={Boolean(errors.name)}
                                                helperText={errors.name?.message}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Email"
                                                variant="filled"
                                                type='email'
                                                className="custom-textfield"
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        color: 'white',
                                                    },
                                                    '& .MuiFormHelperText-root.Mui-error': {
                                                        color: 'white',
                                                    },
                                                }}
                                                error={Boolean(errors.email)}
                                                helperText={errors.email?.message}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="confirm_email"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Confirm Email"
                                                type='email'
                                                variant="filled"
                                                className="custom-textfield"
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        color: 'white',
                                                    },
                                                    '& .MuiFormHelperText-root.Mui-error': {
                                                        color: 'white',
                                                    },
                                                }}
                                                error={Boolean(errors.confirm_email)}
                                                helperText={errors.confirm_email?.message}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="event_type"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Event Type"
                                                variant="filled"
                                                select
                                                className="custom-textfield"
                                                fullWidth
                                                defaultValue=""
                                            >
                                                <MenuItem value="wedding">Wedding</MenuItem>
                                                <MenuItem value="party">Party</MenuItem>
                                                <MenuItem value="festival">Festival</MenuItem>
                                                <MenuItem value="corporate">Corporate</MenuItem>
                                                <MenuItem value="other">Other</MenuItem>
                                            </TextField>
                                        )}
                                    />
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Telephone Number"
                                                type='number'
                                                variant="filled"
                                                className="custom-textfield"
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        color: 'white',
                                                    },
                                                    '& .MuiFormHelperText-root.Mui-error': {
                                                        color: 'white',
                                                    },
                                                }}
                                                error={Boolean(errors.phone)}
                                                helperText={errors.phone?.message}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="notes"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Comments / Notes"
                                                type='text'
                                                variant="filled"
                                                multiline
                                                rows={3}
                                                className="custom-textfield"
                                            />
                                        )}
                                    />
                                    <div className='flexContainer fs-16'>
                                        <Controller
                                            name="send_brochure"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <div className="cursor-context">Send Brochure</div>
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        className="custom-checkbox"
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='w-300 pt-4'>
                                        <Button type="submit" variant='contained' className='main-button' endIcon={<ChevronRightIcon />}>
                                            Let's start talking
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {!isSmallScreen &&
                        <Box className="contactInfoFlex contactInfoTent mb-40">
                            <Footer />
                        </Box>
                    }
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} className='rightSideBarBottom'>
                    <div className='body'>
                        <h3 className='p-40'>Your Selection</h3>
                        {items.length > 0 ?
                            <>
                                {items.map((product, index) => (
                                    <div key={index} className="product-item">
                                        <div className="flexSpaceBetween">
                                            <h3>{product.name}</h3>
                                            <img
                                                src={deleteTent}
                                                alt="delete"
                                                className="deleteTent"
                                                onClick={() => handleDelete(product.id)}
                                            />
                                        </div>
                                        <div className="fs-16 fw-7">({product.size || 'No dimensions'})</div>
                                        <div className="fs-16 pt-4">Options:</div>
                                        <ul>
                                            {product.inventories.map(inventory => (
                                                <li key={inventory.id}>
                                                    {inventory.type === "Multiple" ? `${inventory.name}: ${inventory.qty}` : `${inventory.name}`}
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="fs-35 fw-7">£{product.totalPrice.toFixed(2)}</div>
                                        <Typography className="font_opacity">Includes VAT & Insurance</Typography>
                                    </div>
                                ))}
                            </>
                            :
                            <Typography className='fs-16 p-40'>
                                You currently have no items added…
                            </Typography>
                        }
                    </div>
                </Grid>
            </Grid>
            {isSmallScreen &&
                <Box className="contactInfoFlex contactInfoTent mb-40">
                    <Footer />
                </Box>
            }
        </div>
    );
}

export default QuoteDetails;