import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './css/App.css';
import './css/TentSelection.css';
import './css/QuoteDetails.css';
import './css/ThankYou.css';
import Dashboard from './pages/Dashboard';
import TentSelection from './pages/TentSelection';
import QuoteDetails from './pages/QuoteDetails';
import ThankYou from './pages/ThankYou';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/tent" element={<TentSelection />} />
      <Route path="/quote-details" element={<QuoteDetails />} />
      <Route path="/thankyou" element={<ThankYou />} />
    </Routes>
  );
}

export default App;
