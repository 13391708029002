import React, { useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import plusIcon from '../assets/icon-plus.svg';
import checkIcon from '../assets/check.svg';
import galleryIcon from '../assets/icon-gallery.svg';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const BASE_URL = process.env.REACT_APP_API_URL;

const TentSelectionCard = (props) => {
    const {
        products,
        onShortlist,
        selectedCardId, setSelectedCardId, setSelectedProduct, setAdditionalItems, itemQuantities,
        // setItemQuantities
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [shortlistStatus, setShortlistStatus] = useState({});

    const handleImageClick = (imageUrls) => {
        if (imageUrls?.length > 1) {
            setPhotoIndex(0);
            setIsOpen(true);
        }
    };
    const containerRef = useRef(null);

    const scrollLeft = () => {
        containerRef.current.scrollBy({
            left: -300,
            behavior: 'smooth'
        });
    };

    const scrollRight = () => {
        containerRef.current.scrollBy({
            left: 300,
            behavior: 'smooth'
        });
    };
    // const selectCard = (productId, type_id) => {
    //     setSelectedCardId(productId);
    //     const typeObject = Object.values(products).filter(type => type.type_id === type_id);
    //     const productObject = typeObject[0]?.products?.filter(product => product.id === productId);

    //     console.log("typeObject", typeObject, productObject);
    //     if (productObject) {
    //         setSelectedProduct(productObject);
    //         const updatedAdditionalItems = productObject[0].inventories.map(inventory => ({
    //             id: inventory.id,
    //             name: inventory.name,
    //             checked: inventory.default,
    //             price: inventory.price,
    //             type: inventory.type,
    //             qty: inventory.qty,
    //         }));

    //         setAdditionalItems(updatedAdditionalItems);
    //     }
    // };

    const handleShortlist = (product) => {
        // onShortlist(product);
        const totalPrice = calculateTotalPrice(product);
        onShortlist({ ...product, totalPrice });
        setShortlistStatus(prevStatus => ({
            ...prevStatus,
            [product.id]: 'ShortListed'
        }));
        setTimeout(() => {
            setShortlistStatus(prevStatus => ({
                ...prevStatus,
                [product.id]: 'ShortList'
            }));
        }, 1000);
    };

    const calculateTotalPrice = (product) => {
        let total = Number(product.price);

        if (product.inventories && product.inventories.length > 0) {
            total += product.inventories.reduce((subtotal, inventory) => {
                if (inventory.qty > 0) {
                    const qty = itemQuantities?.[inventory.name] || inventory.qty;
                    if (inventory.type === 'Single') {
                        return subtotal + Number(inventory.price);
                    } else if (inventory.type === 'Multiple') {
                        return subtotal + (Number(inventory.price) * Number(qty));
                    }
                }
                return subtotal;
            }, 0);
        }

        return total;
    };


    const productTypes = Object.values(products);
    if (productTypes.length === 0) {
        return <Typography>No products available.</Typography>;
    }

    return (
        <Box>
            {productTypes.map((productTypeObj, index) => (
                <Box key={index} mb={4}>
                    <div className='flexSpaceBetween'>
                        <h2 className='ps-20 pt-6 fs-35'>{productTypeObj.type || "No Type Available"}</h2>
                        <div>
                            <IconButton sx={{ color: "white" }} onClick={scrollLeft}>
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton sx={{ color: "white" }} onClick={scrollRight}>
                                <ArrowForwardIos />
                            </IconButton>
                        </div>
                    </div>
                    <Box className="tentSelectType" ref={containerRef}>
                        {productTypeObj.products?.map((product) => {
                            const images = product?.images || [];
                            const imageUrls = images.map(image => ({
                                id: image.id,
                                name: image.image_name,
                                url: `${BASE_URL}/uploads/${image?.image_name}`
                            }));
                            return (
                                <Box key={product.id}
                                    className="tentSelectionCard"
                                // className={`tentSelectionCard ${selectedCardId === product.id ? 'borderActive' : 'borderBlue'}`}
                                >
                                    <Card className="tentSelectionCardBody"
                                        sx={{
                                            borderRadius: '20px'
                                        }}
                                    // onClick={() => selectCard(product.id, productTypeObj.type_id)}
                                    >
                                        <div style={{ position: 'relative' }}>
                                            {imageUrls.length > 0 && (
                                                <CardMedia
                                                    component="img"
                                                    alt={imageUrls[0].name}
                                                    image={imageUrls[0]?.url}
                                                    onClick={handleImageClick}
                                                    style={{
                                                        cursor: imageUrls.length > 1 ? 'pointer' : 'default',
                                                        width: '100%',
                                                        height: 'auto',
                                                        position: 'relative'
                                                    }}
                                                />
                                            )}
                                            {imageUrls.length > 1 && (
                                                <img
                                                    src={galleryIcon}
                                                    alt="Gallery icon"
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 10,
                                                        right: 10,
                                                        width: 24,
                                                        height: 24
                                                    }}
                                                />
                                            )}
                                            {isOpen && (
                                                <Lightbox
                                                    mainSrc={imageUrls[photoIndex].url}
                                                    nextSrc={imageUrls.length > 1 ? imageUrls[(photoIndex + 1) % imageUrls.length].url : null}
                                                    prevSrc={imageUrls.length > 1 ? imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length].url : null}
                                                    onCloseRequest={() => setIsOpen(false)}
                                                    onMovePrevRequest={() =>
                                                        setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)
                                                    }
                                                    onMoveNextRequest={() =>
                                                        setPhotoIndex((photoIndex + 1) % imageUrls.length)
                                                    }
                                                />
                                            )}
                                        </div>
                                        <CardContent className='tentSekectionCardContent'>
                                            <Typography gutterBottom variant="h3" className='fs-35 c-cyan mb-0' component="div">
                                                {product?.description}
                                            </Typography>
                                            <Typography className='cardContentNumber'>
                                                {product?.size}
                                            </Typography>
                                            <h3>Selected Extras:</h3>
                                            <ul>
                                                {product.inventories
                                                    ?.filter(inventory => inventory.qty > 0)
                                                    .map(inventory => (
                                                        <li key={inventory.id} className='text-capitalize'>
                                                            {inventory.type === "Multiple" ? `${inventory.name}:  ${inventory.qty} ${inventory?.shape ? inventory?.shape : ""}` : inventory.name}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            <div className='tentSekectionCardFooter'>
                                                <div>
                                                    <Typography className='price'>
                                                        £{calculateTotalPrice(product).toFixed(2)}
                                                    </Typography>
                                                    <Typography className='priceDescription'>
                                                        Inc. VAT & Insurance, Exc. Delivery
                                                    </Typography>
                                                </div>
                                                <Button
                                                    type="submit"
                                                    variant='contained'
                                                    className='main-button'
                                                    startIcon={<img src={shortlistStatus[product.id] === 'ShortListed' ? checkIcon : plusIcon} alt="icon" width="15" />}
                                                    onClick={() => handleShortlist(product)}
                                                >
                                                    {shortlistStatus[product.id] || 'ShortList'}
                                                </Button>
                                            </div>
                                        </CardContent>

                                    </Card>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            ))
            }
        </Box >
    );
};

export default TentSelectionCard;
